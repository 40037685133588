import {createContext, useState, useContext} from"react";

const DevPortalContext = createContext();
export const useDevPortalContext = ()=> useContext(DevPortalContext);
 function DevPortalContextProvider(props){
    const [selectedDevs, setSelectedDevs] = useState([]);
    const [selectedDevsName, setSelectedDevsName] = useState([]);
    const value = {
        selectedDevs, 
        setSelectedDevs,
        selectedDevsName, 
        setSelectedDevsName
    }

    
   return ( <DevPortalContext.Provider value = {value}>
        {props.children}
    </DevPortalContext.Provider>)
};

export default DevPortalContextProvider;