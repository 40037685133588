import axios from "axios";
import jwtDecode from "jwt-decode";

export const isTokenExpired = () => {
  const token = localStorage.getItem("token");
  try {
    const { exp } = jwtDecode(token);
    
    const timeLeft = exp - Date.parse(new Date().toString()) / 1000;

    return timeLeft
  } catch (error) {
    return null;
  }
};

// Generates select dropdown dynamically
export const generateDropdown = (options) => {
  return options.map((option, index) => {
    return (
      <option
        key={index}
        value={option.value}
        selected={index === 0}
        disabled={index === 0}>
        {option.value}
      </option>
    );
  });
};

export const mapEngineersToStack = (values = []) => {
  let result = {};
  values.forEach((item) => {
    let stack = item.stack === ".NET" ? "net" : item.stack.toLowerCase();
    if (stack in result) {
      result[stack] += +item.num_of_engineer;
    } else {
      result[stack] = +item.num_of_engineer;
    }
  });

  return result;
};

export const mapDevEngineersToStack = (values = []) => {
  let result = {};
  values.forEach((item) => {
    let stack =
      item.stack === ".NET" || item.stack === ".net" || item.stack === ".Net"
        ? "net"
        : item.stack.toLowerCase();
    let newStack = stack === "quality assurance" ? "qa" : stack.toLowerCase();
    if (newStack in result) {
      result[newStack] += +item.developer_number;
    } else {
      result[newStack] = +item.developer_number;
    }
  });

  return result;
};

export const formatFormValues = (result) => {
  let fields = [];
  for (let key in result) {
    fields.push({ name: key, value: result[key] });
  }
  let formData = {
    submittedAt: Date.now(),
    fields: fields,
  };
  return formData;
};

// sets select dropdown from api call
export const setSelect = (result, str, switcher) => {
  let stackOptions = [{ value: "", label: `Select ${str || "Option"}` }];

  for (let i = 0; i < result.length; i++) {
    let newObj = {};
    newObj["value"] = switcher ? result[i].name : result[i].id;
    newObj["label"] = result[i].name;

    stackOptions.push(newObj);
  }
  return stackOptions;
};

// sets select dropdown from api call
export const setSelectRequest = (result, str) => {
  let stackOptions = [{ value: "", label: `Select ${str || "Option"}` }];

  for (let i = 0; i < result.length; i++) {
    let newObj = {};
    newObj["value"] = result[i].id;
    newObj["label"] = result[i].name;

    stackOptions.push(newObj);
  }
  return stackOptions;
};

// capitalizes a string
export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getCountryList = async () => {
  let response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/reference/countries`
  );
  let result = response.data.data.map((dat, index) => ({
    value: dat.id,
    label: dat.name,
  }));
  result.unshift({ value: "", label: "Select Country" });

  return result;
};

export const getDevCountryList = async () => {
  let response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/reference/countries`
  );
  let result = response.data.data.map((dat, index) => ({
    value: dat.name,
    label: dat.name,
  }));
  result.unshift({ value: "", label: "Select Country" });

  return result;
};

export const randomString = (length, chars) => {
  var mask = "";
  if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
  if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (chars.indexOf("#") > -1) mask += "0123456789";
  var result = "";
  for (var i = length; i > 0; --i)
    result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};

function getCode(companyName) {
  let nameArr = companyName.split(" ");
  let result = "";

  result = nameArr[0];

  if (nameArr[0].length > 2) {
    result = nameArr[0].substring(0, 3);
  } else {
    result = nameArr[0];
  }
  return result;
}
getCode("Lg");

export const formatErrors = (errorBody, errorSetter) => {
  console.log(errorBody[0].field);

  for (let i = 0; i < errorBody.length; i++) {
    let keys = errorBody[i];
    if (errorSetter[keys.field]) {
      errorSetter[keys.field](keys.message);
    } else {
      errorSetter["others"](keys.field, keys.message);
    }
  }
};

export const convertCurrencyToInt = (amount) => {
  let newNum = amount.split("");
  let amt = newNum.filter((a) => a !== "₦" && a !== "," && a).join("");
  return parseInt(amt);
};

export function getParameterByName(name) {
  var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

const numElements = 9;
const namesOfDummyEngineers = [
  "John Doe",
  "Jane Vax",
  "Tyler Rex",
  "John Ellen",
  "Jane Vogue",
  "Michael Roe",
  "Gill Chapel",
  "Oyinkansola A.",
  "Melody Collins",
];

const dummyImages = [
  "/images/mary_avatar.png",
  "/images/saheed_avatar.png",
  "/images/honoura_avatar.png",
  "/images/odira_avatar.png",
  "",
  "/images/abdulhafiz_avatar.png",
  "/images/ikenna_avatar.png",
  "/images/oyinkansola_a.png"
]
export const dummyData = Array.from({ length: numElements }, (_, i) => ({
  id: i + 1,
  stacks: ["Node", "Python"],
  name: namesOfDummyEngineers[i],
  frameworks: ["React", "Nestjs", "Nestjs", "Angular js", "Rails.js"],
  about: "I am a software developer",
  profilePic: dummyImages[i] ? dummyImages[i] :"/images/profile-img.svg",
  userName: `Profile ${i + 1}`,
  school: "University of Abuja",
  age: 23,
  location: "Lagos",
}));

export const truncateStr = (str) => {
  return str?.length > 120 ? `${str.slice(0, 120)}...` : str;
};

export let youtubeTagRegex = /(?:youtube(?:-nocookie)?\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/