import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiFillYoutube,
  AiFillFacebook,
} from "react-icons/ai";

const Footer = () => {
  return (
    <FooterStyles>
      <img
        src="/images/profiles_logo_white.svg"
        alt="Profiles Logo"
        className="logo"
      />
      <ul>
        <li>
          <Link to={"/privacy"}>Privacy Policy</Link>
        </li>
        <li>
          <Link to={"/terms"}>Terms of Service</Link>
        </li>
      </ul>

      <div className="border"></div>

      <div className="copyrights-and-links">
        <div className="copyright">
          <p className="copyright-text">
            © {new Date().getFullYear().toString()} Decagon Institute. All
            rights reserved
          </p>
        </div>
        <div className="right-sect">
          <div className="links">
            <a
              href="https://web.facebook.com/decagonhq/"
              target={"_blank"}
              className="link"
              rel="noreferrer"
            >
              <AiFillFacebook />
            </a>
            <a
              href="https://www.instagram.com/decagonhq/"
              target={"_blank"}
              className="link"
              rel="noreferrer"
            >
              <AiOutlineInstagram />
            </a>
            <a
              href="https://twitter.com/DecagonIns"
              target={"_blank"}
              className="link"
              rel="noreferrer"
            >
              <AiOutlineTwitter />
            </a>
            <a
              href="https://www.youtube.com/@DecagonHQ"
              className="link"
              target={"_blank"}
              rel="noreferrer"
            >
              <AiFillYoutube />
            </a>
          </div>
          <p>info@decagonhq.com</p>
        </div>
      </div>
    </FooterStyles>
  );
};

const FooterStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0 3.2rem 0;
  font-size: 1.6rem;
  background: #1d2939;
  color: #fff;

  p {
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
  }
  ul {
    display: flex;
    align-items: center;
    gap: 3.2rem;
    list-style: none;
    margin-top: 2.4rem;
    margin-bottom: 3.873rem;
  }
  li {
    cursor: pointer;
  }

  .copyrights-and-links a {
    color: #1d2939;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .logo {
    width: 10.055rem;
  }
  .border {
    width: 88.8%;
  }
  .copyrights-and-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 82.3%;
    margin-top: 3.2rem;
  }
  .links {
    display: flex;
    gap: 1.6rem;
    align-items: center;
  }

  .link {
    height: 2.4rem;
    width: 2.4rem;
    background-color: #e8e8e9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.8rem;
  }

  @media (max-width: 590px) {
    .copyrights-and-links {
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 2.4rem;
    }
    .right-sect {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .copyright-text {
      text-align: center;
      margin-top: 2.4rem;
    }
  }
`;

export default Footer;
