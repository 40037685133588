import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Header = ({ isMenuClicked, setIsMenuClicked }) => {
  return (
    <StyledHeader>
      <div className="home_parent_wrapper section_wrapper">
        <Link to="/">
          <img
            src="/images/profiles_logo_dark.svg"
            alt="Decagon Profiles Logo"
            className="logo"
          />
        </Link>
        <ul>
          <li>
            <Link to={"/signup"} className="signup">
              Sign Up
            </Link>
          </li>
          <li>
            <Link to={"/devlist"} className="view-profile">
              See more Profiles
            </Link>
          </li>
        </ul>
      </div>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  font-size: 1.6rem;
  width: 100%;
  background-color: #fff;
  user-select: none;
  z-index: 99;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);

  .section_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 94px;
  }

  .logo {
    cursor: pointer;
    width: 14.7rem;
  }

  ul {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    list-style: none;
    margin-bottom: 0;
  }
  li {
    cursor: pointer;
  }
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  a {
    color: inherit;
    text-decoration: none;
  }

  .signup {
    font-weight: 600;
  }

  .view-profile {
    font-family: "Inter", sans-serif;
    display: inline-block;
    text-align: center;
    background-color: #34a853;
    font-weight: 600;
    color: #ffffff;
    width: 26.3rem;
    padding: 1.6rem;
    border-radius: 10rem;
    transition: all 0.2s ease-in-out;
  }
  .view-profile:hover {
    background-color: #2f8a4c;
  }
  .view-profile:active {
    opacity: 0.8;
  }

  .first-links {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    margin-bottom: 3.2rem;
    width: 100%;
    padding: 3.2rem 1.6rem;
  }

  .second-link {
    background-color: #219653;
    color: #ffffff;
    text-align: center;
    padding: 1.6rem;
    border-radius: 10rem;
    transition: all 0.2s ease-in-out;
  }
  .second-link:active {
    opacity: 0.8;
  }

  .absolute-header {
    display: flex;
    justify-content: space-between;
    padding: 3.4rem 1.6rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #eeeeee;
  }

  @media screen and (max-width: 600px) {
    .logo {
      width: 8.7rem;
    }

    .section_wrapper {
      height: 64px;
    }

    ul {
      gap: .5rem;
      font-size: 1.4rem;
    }

    .view-profile {
      font-weight: 600;
      color: #ffffff;
      width: 14.8rem;
      padding: 1rem 1.6rem;
      border-radius: 10rem;
      transition: all 0.2s ease-in-out;
      white-space: nowrap;
    }
  }
`;
