import axios from "axios";

const getAccessToken = async () => {
  const accessPayload = {
    accessToken: localStorage?.getItem("token"),
    refreshToken: localStorage?.getItem("refreshToken"),
    userName: localStorage?.getItem("email")
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/private/refreshToken`,
      {
        method: "POST",
        body: JSON.stringify(accessPayload),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const res = await response.json();

    if (res.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }

    return res.data.token;
  } catch (err) {
    console.log({err});
  }
};


axios.interceptors.response.use(null, async (err) => {
  if (err.response.status === 401) {
    let originalReq = err.config;
    const token = await getAccessToken();
    if (token) {
      localStorage.setItem("token", token);
      originalReq.headers["Authorization"] = `Bearer ${token}`;
      return axios(originalReq);
    }

    if (token === undefined) {
      localStorage.clear();
      window.location.href = "/login";
    }
  }
  else {
    return Promise.reject(err);
  }
});


export const apiGet = (path, { headers, ...conf }, auth=true) => {
  const Authorization = auth && `Bearer ${localStorage.getItem("token")}`;
  const config = {
    ...conf,
    headers: {
      Authorization,
      ...(headers ? headers : {}),
    },
  };
  return axios.get(`${process.env.REACT_APP_BASE_URL}${path}`, config);
};

export const apiPost = (path, data, { headers, ...conf }, auth = true) => {
  const Authorization = auth && `Bearer ${localStorage.getItem("token")}`;
  const config = {
    ...conf,
    headers: {
      Authorization,
      ...(headers ? headers : {}),
    },
  };

  return axios.post(`${process.env.REACT_APP_BASE_URL}${path}`, data, config);
};

export const apiPut = (path, data, conf = {}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    ...conf,
  };
  return axios.put(`${process.env.REACT_APP_BASE_URL}${path}`, data, config);
};

export const apiPatch = (path, data, conf = {}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    ...conf,
  };
  return axios.patch(`${process.env.REACT_APP_BASE_URL}${path}`, data, config);
};

export const apiDelete = (path, conf = {}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    ...conf,
  };
  return axios.delete(`${process.env.REACT_APP_BASE_URL}${path}`, config);
};

export const errorHandler = (payload) => { };
