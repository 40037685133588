import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  forgotPassword,
  logout,
  resetPassword,
  signIn,
  signUp,
  redirectFellowship,
} from "./api";

export const useSignIn = () => {
  const history = useHistory();

  const mutation = useMutation(signIn, {
    onSuccess: () => {
      toast.success("Login successful");
      if (history.location.pathname.includes("/login")) {
        history.push("/devlist");
      } else {
        window.location.reload();
      }
    },
    onError: (err) => {
      toast.error(err.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  return mutation;
};

export const useSignUp = (organisationId) => {
  const history = useHistory();

  const mutation = useMutation((payload) => signUp(payload, organisationId), {
    onSuccess: () => {
      toast.success("Sign up successful");
      history.push("/login");
    },
    onError: (err) => {
      toast.error(err.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  return mutation;
};

export const useForgotPassword = () => {
  const history = useHistory();

  const mutation = useMutation(forgotPassword, {
    onSuccess: () => {
      toast.success("Password reset link sent to your email");
      history.push({
        pathname: "/confirm-email",
      });
    },
    onError: (err) => {
      toast.error(err.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  return mutation;
};

export const useResetPassword = (modalToggle) => {
  const mutation = useMutation(resetPassword, {
    onSuccess: () => {
      toast.success("Password reset successful");
      modalToggle(true);
    },
    onError: (err) => {
      toast.error(err.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  return mutation;
};

export const useLogout = (config) => {
  const history = useHistory();

  const mutation = useMutation((data) => logout(data, config), {
    onSuccess: () => {
      localStorage.clear();
      history.push("/");
    },
    onError: (err) => {
      toast.error(err.response.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  return mutation;
};

export const useRedirectFromFellowship = (token, email) => {
  const history = useHistory();
  const query = useQuery(
    [{ token: token, email: email }, email],
    redirectFellowship,
    {
      onSuccess: () => {
        history.push("/devlist");
      },
      onError: (err) => {
        toast.error(err.response.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },

      enabled: token && email ? true : false,
    }
  );

  return query;
};
