import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import { clarity } from "react-microsoft-clarity";
import LinkedInTag from "react-linkedin-insight";

const TRACKING_ID = "G-DMGDNCT6HS";

export function init() {
  ReactGA.initialize(TRACKING_ID, { debug: false });
}

export function sendEvent(payload) {
  ReactGA.event(payload);
}

export function sendPageview(path) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}

export function initClarity() {
  clarity.init("jmpeh5xyov");
}

export function initLinkedin() {
  LinkedInTag.init("5581012");
}

export function initHotjar() {
  hotjar.initialize(3724652, 6);
}

export function hotJarStateChange(path) {
  hotjar.stateChange(path);
}
