import React from "react";
import { useLocation } from "react-router-dom";

import {
  hotJarStateChange,
  init,
  initClarity,
  initHotjar,
  initLinkedin,
  sendPageview,
} from "./analytics";

export default function useGoogleAnalytics() {
  const location = useLocation();

  React.useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      init();
      initClarity();
      initHotjar();
      initLinkedin();
    }
  }, []);

  React.useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const currentPath = location.pathname + location.search;
      sendPageview(currentPath);
      hotJarStateChange(currentPath);
    }
  }, [location]);
}
