import React from "react";
import { Route } from "react-router-dom";
import { isTokenExpired } from "../../utils/helperFunctions";
import UserSignInRequest from "../modals/UserSignInRequest";

const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  const isUserSignedIn = isTokenExpired()
  
  return (
    <>
      {!isUserSignedIn && <UserSignInRequest />}
      <Route {...rest} render={(props) => <Component {...props} />} />
    </>
  );
};

export default ProtectedRoute;
