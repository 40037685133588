import { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";

const Privacy = () => {
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Header
        isMenuClicked={isMenuClicked}
        setIsMenuClicked={setIsMenuClicked}
      />
      
    <StyledPrivacy>
      <h1>Privacy Policy</h1>
      <div className="column-layout">
        <div className="privacy-heading">
            <ul>
                <li>Introduction</li>
                <li>Personal information</li>
                <li>Purpose of Collecting Such Data</li>
                <li>Retention of information</li>
                <li>Disclosure &amp; Repurposing</li>
                <li>Storage of Information</li>
                <li>Inquiries about the Privacy Policy</li>
            </ul>
        </div>
        <div className="privacy-content">
            <h2>DECAGON SERVICES INC PRIVACY POLICY</h2>
            <h3>Decagon values individual privacy, and here’s a quick rundown of what information we collect about visitors to our website and how we use this.</h3>
            <p>This Privacy Policy explains:</p>
            <ul>
                <li>Personal Information Collected</li>
                <li>Purpose of Collecting Personal Information</li>
                <li>Retention of Personal Information.</li>
                <li>Disclosure of Personal Information.</li>
                <li>Storage of Personal Information and Access Right.</li>
                <li>Contact us.</li>
            </ul>
            <h4>The Information we collect</h4>
            <p>a. When navigating our DecagonHQ.com and the pages connected to it, we store non-personal identification of your browser, using Cookies. This only tells us generic information about the browser you use, the location of your IP address, and other browser information you allow to show publicly.</p>
            <p>“Cookies,” also called HTTP cookies are small blocks of data placed in your computer’s browser to store non-personal information, s explained above.</p>
            <p>The only purpose of this is to help us personalize your website experience, and enable our Marketing team to understand our website audience a little better. Decagon does not use Cookies to track or collect personal identification information from website users. You may adjust the settings on your browser to warn you before a Cookie is stored, or erase active Cookies as you may like</p>
            <p>b. Information is collected when you volunteer to identify yourself and provide requested information, such as when you write to us or fill out certain forms or online data requests, which may include your name, telephone number, e-mail address, job title and related information.</p>
            <h4>Purpose of Collecting Personal Information</h4>
            <p>Decagon uses the information collected in “b.” to:</p>
            <p>(i) to respond to your inquiries;</p>
            <p>(ii) evaluate your request or application</p>
            <p>(iii) send you appropriate information in regards to news, events, job openings and related information you subscribed to, and</p>
            <p>(iv) for direct marketing purposes, including to send you newsletters, product notifications alerts and information we blieve may be of interest to you etc.</p>
            <p>In some cases, such as requests for Software Developers, the Sales &amp; Placement team at Decagon may also use such data to communicate with you about your inquiries.</p>
            <p>In addition to this, we also use some of the data collected, for the purpose of research and product-market alignment – all without overriding the privacy of your personal information.</p>
            <h4>Retention of Personal Information.</h4>
            <p>Decagon will retain your personal information for as long as necessary to fulfill the purposes for which it was collected or as necessary to comply with our legal obligations, resolve disputes, and maintain appropriate business records.</p>
            <p>Once the purpose has been fulfilled your personal information will be retained, or archived due to the possibility of legal and governmental requirements and until the statute of limitation has elapsed.</p>
            <h4>Disclosures of Personal Information.</h4>
            <p>Decagon may share your personal information with affiliated companies.</p>
            <p>In addition, Decagon reserves the right to disclose information</p>
            <ul>
                <li>where required by law; or</li>
                <li>to comply with a valid legal process (such as a search warrant, subpoena or court order); or</li>
                <li>to protect Decagon’s rights or property, including without limitation in the event of a transfer of control of Decagon or substantially all of its assets; or</li>
                <li>during emergencies when safety is at risk.</li>
            </ul>
            <h4>Storage of Personal Information and Access Right.</h4>
            <ul>
                <li>Information collected by Decagon through the Website is stored on secured servers, to protect against unauthorized use and access. In some cases, we also have personal informatio stored on trusted CRM tools, for easy reference.</li>
                <li>As Users of DecagonHQ.com, you can exercise your rights of access, rectification, erasure, restriction, opposition, personal information portability, and/ or withdrawal of consent regarding your personal information which is stored in our server by sending an email to the following address:&nbsp;<a href="mailto:privacy@decagonhq.com">privacy@decagonhq.com</a></li>
            </ul>
            <h4>Contact Us</h4>
            <p>If you have questions or concerns regarding the way in which your personal data is being processed or regarding this Policy, please send us an email at&nbsp;<a href="mailto:privacy@decagonhq.com">privacy@decagonhq.com</a>.</p>
        </div>
      </div>
    </StyledPrivacy>
    <Footer />
    </>
  );
};

const StyledPrivacy = styled.section`
    padding: 20px;
    display: block;
    margin: 50px auto;
    max-width: 1080px;
    font-size: 1.6rem;
    line-height: 3rem;
    color: #212529;
    padding-top: 64px;
    background: #eee;

    a{
        color: #34a853;
        font-weight: bold;
    }

    h1{
        font-size: 6rem;
        margin-bottom: 26px;
    }

    .column-layout {
        display: flex;
        flex-wrap: wrap;
    }

    .privacy-heading{
        flex-basis: 33.1%;
    }

    ul{
        margin-left: 16px;
    }

    .privacy-content{
        flex-basis: 66.6%
    }

    @media (max-width: 760px) {
        .terms-heading{
            flex-basis: 100%
        }
    
        .terms-content{
            flex-basis: 100%
        }
    }
`;
export default Privacy;
