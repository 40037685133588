import { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";

const Terms = () => {
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Header
        isMenuClicked={isMenuClicked}
        setIsMenuClicked={setIsMenuClicked}
      />
      
    <StyledTerms>
      <h1>Terms & Conditions</h1>
      <div className="column-layout">
        <div className="terms-heading">
            <ul>
                <li>Introduction</li>
                <li>Copyright and Trademark Notice</li>
                <li>General Legal Notice and Liability Disclaimer</li>
                <li>Privacy Policy</li>
                <li>Severability</li>
            </ul>
        </div>
        <div className="terms-content">
            <h2>DECAGON SERVICES INC T&amp;C</h2>
            <h3>The following Terms and Conditions shall govern the use of each of the websites located at, or linked to, the URL www.decagonhq.com</h3>
            <p>This includes:</p>
            <ul>
                <li>the services that may be offered in the site;</li>
                <li>the information provided in the sites;</li>
                <li>and the ability to register into our database, receive newsletters and promotional emails;</li>
            </ul>
            <p>Please read the following Terms and Conditions carefully.</p>
            <p>By using any one of our Sites, the site visitor (“the user”) acknowledges that he/ she has read, understood and expressly agrees to be legally bound by the Terms and Conditions contained herein and all applicable laws and regulations governing our Sites.</p>
            <p>These Terms and Conditions shall supersede all terms or conditions included with any purchase order, whether or not such terms or conditions have been signed by Decagon (“Decagon” “we” or “us”).</p>
            <p>Decagon reserves the right to review and update these Terms and Conditions at any time, effective immediately upon posting on our Sites. A violation of these Terms and Conditions, shall lead to a termination of the users access to the site and/or appropriate legal action against the user by Decagon.</p>
            <h4>Copyright and Trademark Notice</h4>
            <p>The contents of all material available on our Sites are copyrighted by Decagon unless otherwise indicated. All rights are reserved and content may not be reproduced, downloaded, disseminated, or transferred, in any form or by any means, except with the prior written consent of Decagon or as indicated below:</p>
            <p>Permitted Use:</p>
            <ul>
                <li>The user may download pages or other content for personal use on a single computer, but no part of such content may be otherwise or subsequently reproduced, downloaded, disseminated, or transferred, in any form or by any means, except with the prior written consent of, and with express attribution to, Decagon.</li>
                <li>The user agrees that he/she shall only be authorized to visit, view and to retain a copy of pages of the Sites for personal use, and that he/she shall not duplicate, download, publish, modify or otherwise distribute the material on the Sites for any purpose other than for personal use, unless otherwise specifically authorized by Decagon to do so.</li>
                <li>The user also agrees not to deep-link to the site for any purpose, unless specifically authorized by us to do so.</li>
            </ul>
            <h4>General Legal Notice and Liability Disclaimer</h4>
            <ul>
                <li>Decagon shall make reasonable effort to present accurate and reliable information only on its Sites, however Decagon does not endorse, approve or certify such information, nor does it guarantee the accuracy, completeness, efficacy, or timeliness of such information. Therefore, the use of such information is voluntary, and reliance on it should only be undertaken after an independent review by qualified experts.</li>
                <li>Reference herein to any specific commercial product, process or service does not constitute or imply endorsement, recommendation or favoring by Decagon. At certain places on this site, live “links” to other Web sites can be accessed. Such external sites contain information created, published, maintained, or otherwise posted by institutions or organizations independent of Decagon. Decagon does not endorse, approve, certify, or control these external sites and does not guarantee the accuracy, completeness, efficacy, or timeliness of information located at such sites. The use of any information obtained from such sites is voluntary, and reliance on it should only be undertaken after an independent review by qualified experts.</li>
                <li>Decagon assumes no responsibility for consequences resulting from use of the information contained in its Sites, or from use of the information obtained at linked sites, or in any respect for the content of such information. Decagon is not responsible for, and expressly disclaims all liability for, damages of any kind arising out of use, reference to, reliance on, or performance of such information.</li>
            </ul>
            <h4>Privacy Policy.</h4>
            <p>Decagon values individual privacy. Please visit our <a href="/privacy" data-type="page" data-id="653">privacy policy page</a> to learn more about what information we collect from our site visitors and how it is treated.</p>
            <h4>Severability.</h4>
            <p>The invalidity or unenforceability of any particular provision of this Policy shall not affect the remaining provisions hereof, and this Policy shall be construed in all respects as if such invalid or unenforceable provision had been omitted.</p>
        </div>
      </div>
    </StyledTerms>
    <Footer />
    </>
  );
};

const StyledTerms = styled.section`
    padding: 20px;
    display: block;
    margin: 50px auto;
    max-width: 1080px;
    font-size: 1.6rem;
    line-height: 3rem;
    color: #212529;
    padding-top: 64px;
    background: #eee;

    a{
        color: #34a853;
        font-weight: bold;
    }

    h1{
        font-size: 6rem;
        margin-bottom: 26px;
    }

    .column-layout {
        display: flex;
        flex-wrap: wrap;
    }

    .terms-heading{
        flex-basis: 33.1%;
    }

    ul{
        margin-left: 16px;
    }

    .terms-content{
        flex-basis: 66.6%
    }

    @media (max-width: 760px) {
        .terms-heading{
            flex-basis: 100%
        }
    
        .terms-content{
            flex-basis: 100%
        }
    }
`;
export default Terms;
