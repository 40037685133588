import React, {lazy, Suspense} from 'react';
import styled from "styled-components"
import MyButton from '../landingPage/MyButton';
import { Link } from "react-router-dom";

const WelcomePage = () => {
    const WelcomeComponent = lazy(() => import("../StyleComponents/StyleComponents")); 
    return (
        <StyledWelcome>
            <div className="welcome-left">
                <div className="logo-image__container">
                    <img className="welcome-logo" src="./images/decagon-dark-logo.svg" alt="Profiles Logo" />
                </div>

                <div className="welcome-left__text">
                    <p className="welcome-left__content mb-1">SEE OUR DECADEV PROFILES</p>
                    <h2 className="welcome-left__title">Build faster with our vetted remote software engineering team.</h2>
                    <p className="welcome-left__content">Select from a talent pool of 400+ vetted and exceptional software engineers who are building for global companies around the world.</p>
                    <Link to="/devlist" ><MyButton>Get Started</MyButton></Link>

                </div>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
            <WelcomeComponent/>
            </Suspense>
        </StyledWelcome>
    )
}
const StyledWelcome = styled.div`
height: 100vh;
display: flex;
flex-wrap: wrap;
.logo-image__container {
    max-width: 567px;
    margin: 0 auto;
}
.welcome-right{
    background-image: url("./images/rightcontentimage.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    flex: 50%;
}
.welcome-left{
    padding: 4.3rem 8.9rem 4rem 6.4rem;
    flex: 50%;
    margin-bottom: auto;
    height: 100%;
    width: 100%;
}
.welcome-logo{
    width: 30%;
}
.welcome-left__text{
    font-family: Mulish;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 567px;
    margin: 0 auto;

}
.welcome-left__link{
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #34A853;
}

.welcome-left__title{
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    color: #21334F;
    margin-top: 0rem;
    margin-bottom: 3.2rem;

}
a{
    text-decoration: none;
}
.welcome-left__content{
    font-size: 18px;
    line-height: 32px;
    color: #21334F;
    margin-bottom: 4.8rem;
}
@media screen and (max-width: 600px) {
    .welcome-logo{
        width: 40%;
    }
    .welcome-left{
        padding: 4.3rem 2rem;
        &__title{
            font-size: 30px;
            line-height: 40px;
        }
        &__content{
            text-align: justify;
        }

    }
}

`;

export default WelcomePage
