import axios from "axios";
import { apiPost } from "../../utils/apiHelpers";
import nprogress from "nprogress";

export const signIn = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/private/login`,
      data
    );
    const token = res.data.data.token;
    const refreshToken = res.data.data.refreshToken;
    const organisationId = res.data.data.user.organisationId;
    const email = res.data.data.user.email;
    const firstName = res.data.data.user.firstName;

    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("organisationId", organisationId);
      localStorage.setItem("email", email);
      localStorage.setItem("firstName", firstName);
    }
    if (refreshToken) localStorage.setItem("refreshToken", refreshToken);

    return res.data.data;
  } catch (err) {
    throw err;
  }
};

export const signUp = async (data, organisationId) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/signUp/${organisationId}`,
      data
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};

export const forgotPassword = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/forgotpassword/devprofile`,
      data
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};

export const resetPassword = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/resetpassword/client`,
      data
    );
    return res.data.data;
  } catch (err) {
    throw err;
  }
};

export const logout = async (data, config) => {
  try {
    nprogress.start();
    const res = await apiPost(`/private/logout`, data, config);
    nprogress.done();
    return res.data;
  } catch (err) {
    nprogress.done();
    throw err;
  }
};

export const redirectFellowship = async (params) => {
  const [redirects] = params.queryKey;

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/private/auth-client/${redirects.email}`,
      {
        headers: {
          Authorization: "Bearer " + redirects.token,
        },
      }
    );

    const token = res.data.token;
    const refreshToken = res.data.refreshToken;
    const organisationId = res.data.user.organisationId;
    const email = res.data.user.email;
    const firstName = res.data.user.firstName;

    if (token) {
      localStorage.setItem("token", token);
      localStorage.setItem("organisationId", organisationId);
      localStorage.setItem("email", email);
      localStorage.setItem("firstName", firstName);
    }
    if (refreshToken) localStorage.setItem("refreshToken", refreshToken);

    return res.data;
  } catch (err) {
    throw err;
  }
};
