import React from "react";
import styled from "styled-components";
import { RiLoginBoxFill } from "react-icons/ri";
import SignInForm from "../form/SignInForm";
import { Link } from "react-router-dom";

const UserSignInRequest = () => {
  return (
    <UserSignInRequestStyles>
      <div className="modal-content">
        <RiLoginBoxFill color="#34a853" size="5rem" />
        <p className="heading">Hi, welcome back</p>
        <SignInForm />
        <p className="create-account-text">
          Don't have an account?{" "}
          <Link to={"/signup"} className="signup-link">
            Create Account
          </Link>
        </p>
      </div>
    </UserSignInRequestStyles>
  );
};

export default UserSignInRequest;

const UserSignInRequestStyles = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
  }

  a {
    text-decoration: none;
    color: #34a853;
  }

  .modal-content {
    background-color: #ffffff;
    padding: 4rem;
    border-radius: 2.4rem;
    width: 47.9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .heading {
    font-weight: 700;
    font-size: 2.4rem;
    color: #21334f;
    margin-bottom: 2.4rem !important;
    margin-top: 4rem !important;
  }

  .subtext {
    font-size: 1.4rem;
    color: #101828;
    margin-bottom: 3.2rem;
    text-align: center;
  }

  .close-icon {
    align-self: flex-end;
    cursor: pointer;
    user-select: none;
    color: #b3b3b3;
    margin-bottom: 2rem;
    transition: all 0.2s ease-in-out;
  }
  .close-icon:hover {
    color: #333333;
  }

  .create-account-text {
    font-size: 1.4rem;
  }

  @media (max-width: 570px) {
    .modal-content {
      width: 92%;
      padding: 2.4rem;
    }

    .create-account-text {
      text-align: center;
    }
  }
`;
