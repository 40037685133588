import React from "react";
import MyButton from "../components/landingPage/MyButton";

const ErrorPage = ({ type }) => {
  return (
    <>
      {type === "404" ? (
        <h1>
          This page does not exist, click <MyButton href="/">Here</MyButton> to
          go back
        </h1>
      ) : (
        <h1>
          Hello there you reached an error page here, click{" "}
          <MyButton href="/">Here</MyButton> to go back
        </h1>
      )}
    </>
  );
};

export default ErrorPage;
