import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomLoader from "./components/common/CustomLoader";
import ErrorBoundary from "./components/common/ErrorBoundary";
import DevPortalContextProvider from "./context/DevPortalContext";
// import WelcomePage from "./components/DevPortal/WelcomePage";
import { QueryClient, QueryClientProvider } from "react-query";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import Terms from "./pages/Terms";
import ErrorPage from "./pages/ErrorPage";
import "nprogress/nprogress.css";
import Privacy from "./pages/Privacy";
import useGoogleAnalytics from "./utils/useAnalytics";
import WelcomePage from "./components/devList/WelcomePage";
import TagManager from "react-gtm-module";

const Home2 = React.lazy(() => import("./pages/Home"));

const SignUp = React.lazy(() => import("./pages/auth/SignUp"));

const Login = React.lazy(() => import("./pages/auth/Login"));

const Verify = React.lazy(() => import("./pages/auth/Verify"));

const ForgotPassword = React.lazy(() => import("./pages/auth/ForgotPassword"));

const ConfirmEmail = React.lazy(() => import("./pages/auth/ConfirmEmail"));

const ResetPassword = React.lazy(() => import("./pages/auth/ResetPassword"));

const FromFellowshipPageRedirectPage = React.lazy(() =>
  import("./pages/auth/FromFellowshipPageRedirectPage")
);

const DevProfile = React.lazy(() => import("./pages/DevProfile"));

const DevList = React.lazy(() => import("./pages/DevList"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  },
});

const tagManagerArgs = {
  gtmId: "GTM-PPLF4RFZ",
};

TagManager.initialize(tagManagerArgs);

function App() {
  useGoogleAnalytics();
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Suspense fallback={<CustomLoader />}>
          <DevPortalContextProvider>
            <>
              <ToastContainer style={{ fontSize: "16px" }} />
              <Switch>
                <Route exact path="/">
                  <Home2 />
                </Route>
                <Route exact path="/signup">
                  <SignUp />
                </Route>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/verify">
                  <Verify />
                </Route>
                <Route exact path="/forgot-password">
                  <ForgotPassword />
                </Route>
                <Route exact path="/confirm-email">
                  <ConfirmEmail />
                </Route>
                <Route exact path="/resetpassword/client/:token">
                  <ResetPassword />
                </Route>

                <Route exact path="/fellowship/redirect">
                  <FromFellowshipPageRedirectPage />
                </Route>

                <Route exact path="/terms">
                  <Terms />
                </Route>

                <Route exact path="/privacy">
                  <Privacy />
                </Route>

                {/* welcome page */}
                <Route exact path="/welcome">
                  <WelcomePage />
                </Route>

                {/* dev portal */}
                <ProtectedRoute
                  exact
                  path="/profile"
                  component={() => <DevProfile />}
                />

                <ProtectedRoute
                  exact
                  path="/devlist"
                  component={() => <DevList />}
                />

                <Route path="*" component={() => <ErrorPage type="404" />} />
              </Switch>
            </>
          </DevPortalContextProvider>
        </Suspense>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
