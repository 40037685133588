import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSignIn } from "../../services/auth/query";
import BeatLoader from "react-spinners/BeatLoader";

const SignInForm = () => {
  const { mutate, isLoading } = useSignIn();

  const [formData, setFormData] = useState({});
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(formData);
  };
  return (
    <SignInFormStyles action="" className="form" onSubmit={handleSubmit}>
      <label htmlFor="userName">Email</label>
      <div className="email-input input-container">
        <img src="/images/email-icon.svg" alt="" />
        <input
          required
          type="text"
          name="userName"
          placeholder="Email"
          onChange={handleChange}
          value={formData.userName || ""}
        />
      </div>

      <label htmlFor="password">Password</label>
      <div className="password-input input-container">
        <img src="/images/password-icon.svg" alt="" />
        <input
          required
          type="password"
          name="password"
          placeholder="Password"
          onChange={handleChange}
          value={formData.password || ""}
        />
      </div>

      <Link to={"/forgot-password"} className="forgot-text">
        Forgot password?
      </Link>

      <button type="submit" className="submit">
        {isLoading ? (
          <BeatLoader color={"#ffffff"} loading={isLoading} size={12} />
        ) : (
          "Login"
        )}
      </button>
    </SignInFormStyles>
  );
};

export default SignInForm;

const SignInFormStyles = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  a {
    text-decoration: none;
    color: #34a853;
  }

  .signup-link {
    font-weight: 600;
  }

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }

  label {
    color: #012a4a;
    font-weight: 600;
    font-size: 1.4rem;
  }
  input {
    width: 100%;
    font-size: 16px;
  }
  input:-webkit-autofill {
    transition: background-color 1s 5000s;
  }

  input::placeholder {
    font-size: 1.4rem;
    color: #98a2b3;
  }
  .input-container {
    display: flex;
    gap: 0.8rem;
    border: 1px solid #98a2b3;
    border-radius: 0.6rem;
    padding: 1.2rem 1.6rem;
  }

  .email-input {
    margin-bottom: 1.6rem;
  }

  .password-input {
    margin-bottom: 0.8rem;
  }

  .forgot-text {
    color: #1570ef;
    margin-bottom: 3.2rem;
    font-size: 1.4rem;
  }
  .submit {
    padding: 1.2rem 0;
    border-radius: 0.6rem;
    background-color: #34a853;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
  }
  .submit:hover {
    background-color: #2e8a4a;
  }
`;
